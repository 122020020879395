import HeroText from '/components/lp/sections/hero/HeroText';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import classNames from '/utils/class-names';

const HeroV2 = ({ config }) => {
	const alignments = {
		center: 'justify-center items-start',
		left: 'md:ml-[4vw] xl:ml-[10vw] items-end mb-10 md:pb-32',
		right: 'xl:mr-[4vw] items-end',
	};

	return (
		<div className="relative bg-gray-900 overflow-hidden min-h-[75vh]">
			<div aria-hidden="true" className="absolute inset-0 overflow-hidden">
				{!config.videoSrc && config.imageSrcMobile && config.imageSrcDesktop ? (
					<div className="absolute inset-0 overflow-hidden">
						<div className="block md:hidden h-full w-full">
							<Image
								alt="Shinesty homepage image mobile"
								className="object-cover object-center min-h-[75vh]"
								layout="fill"
								src={config.imageSrcMobile}
								priority
							/>
						</div>
						<div className="hidden md:block h-full w-full">
							<Image
								alt="Shinesty homepage image desktop"
								className="object-cover object-left min-h-[75vh]"
								layout="fill"
								src={config.imageSrcDesktop}
								priority
							/>
						</div>
					</div>
				) : (
					<video
						aria-label=""
						autoPlay={'autoplay'}
						className="h-full w-full object-cover object-center"
						controlsList="nodownload"
						id="indexVideo"
						loop
						muted
						playsInline
						poster={config.videoPosterSrc}
						preload="metadata">
						<source src={config.videoSrc} type="video/mp4" />
					</video>
				)}
				{config.showOverlay && <div className="absolute inset-0 bg-gray-900 bg-opacity-60"></div>}
			</div>
			<div
				className={classNames(
					'flex relative min-h-[75vh] px-4',
					!config.content || config.content === ''
						? 'items-end pb-12 md:pb-24 justify-center md:justify-start md:ml-32'
						: '',
					config.content && config.content !== '' && alignments[config.alignment]
						? alignments[config.alignment]
						: '',
					'text-v2-off-white',
				)}>
				<HeroText config={config} />
			</div>
		</div>
	);
};

HeroV2.propTypes = {
	config: PropTypes.object,
};

export default HeroV2;
